import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/admin/authentication/Signin.vue';
import VueMeta from 'vue-meta';
import Axios from 'axios';
import configuration from './configuration';
import VueBodyClass from 'vue-body-class';

import Home from '@/views/admin/dashboard/Dashboard.vue';
import SideBar from '@/layouts/SideBar.vue';
import MainWebsite from '@/layouts/MainWebsite.vue';
import PageNotFound from '@/layouts/PageNotFound.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [{
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: '/sign-admin',
        name: 'login',
        component: Login
    },
    {
        path: '/admin/dashboard',
        name: 'admin.dashboard',
        component: Home,
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/pengguna',
        name: 'master.pengguna',
        component: () => import('@/views/admin/master/MasterPengguna.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/klasifikasi',
        name: 'master.klasifikasi',
        component: () => import('@/views/admin/master/Klasifikasi.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/stasioning',
        name: 'master.stasioning',
        component: () => import('@/views/admin/master/Stasioning.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/sub_klasifikasi',
        name: 'master.sub_klasifikasi',
        component: () => import('@/views/admin/master/SubKlasifikasi.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/master/item_klasifikasi',
        name: 'master.item_klasifikasi',
        component: () => import('@/views/admin/master/ItemKlasifikasi.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    {
        path: '/admin/input',
        name: 'input',
        component: () => import('@/views/admin/input/index.vue'),
        meta: {
            transition: 'fade',
            layout: SideBar,
            roles : ['administrator']
        }
    },
    /* -------------------------------------------------------------- */
    {
        path: '/signout',
        name: 'signout',
        component: () => import('./views/admin/authentication/Signout.vue'),
        meta: {
            transition: 'fade',
            layout: MainWebsite
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: PageNotFound,
    },      
];

const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["login", "signup", "home"];
    const authRequired = !publicPages.includes(to.name);
    const email = localStorage.getItem('email');

    // if user login without twofactor authentication
    if (!email) {
        const token = localStorage.getItem('auth_token');
        if (authRequired && token) {
            Axios.get(configuration.host + 'auth/validate_user', {
                headers: { 'Authorization': token }
            }).then(response => {
                if (!response.data.status) {
                    localStorage.removeItem('id');
                    localStorage.removeItem('auth_token');
                    next('/');
                } else {
                    let roles = response.data.role;
                    
                    if(to.meta.roles.includes(roles)) {
                        next();
                    } else {
                        alert("Akses tidak diperbolehkan");
                        next({ name: 'home' });
                    }
                }
            }).catch(function(error) {
                console.debug(error);
            });
        } else if (authRequired && !token) {
            next({ name: 'home' });
        } else {
            next();
        }
    }

    vueBodyClass.guard(to, next);
});

export default router;