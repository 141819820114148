<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- HEADER -->
                <!-- <button type="button" id="myBtn">Open Modal</button> -->
                <div class="d-block mb-4">
                    <div class="d-flex mt-4 p-3 alert alert-primary text-dark rounded align-items-center">
                        <div class="mr-3"><img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/1bc63603a565f1b35120bb15/2146029.png" width="60"></div>
                        <div>
                            <h4 style="color:black !important" class="text-bold text-dark mb-2">Database JMT</h4>
                            <p class="mb-2">Sistem Informasi Pengelolaan Data</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row row-xs clearfix">

            <div class="col-sm-12 col-xl-4">
                <div class="card mg-b-20  border-white">
                    <div class="card-body pd-y-0">
                        <div class="bg-white custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">GEOTEKNIK</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-primary">
                                    <i class="fa fa-check tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataStatistik.geoteknik }} <a style="font-size:14px">Data</a></span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-4">
                <div class="card mg-b-20  border-white">
                    <div class="card-body pd-y-0">
                        <div class="bg-white custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">DRAINASE</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-primary">
                                    <i class="fa fa-check tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataStatistik.drainase }} <a style="font-size:14px">Data</a></span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-4">
                <div class="card mg-b-20  border-white">
                    <div class="card-body pd-y-0">
                        <div class="bg-white custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">STRUKTUR</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-primary">
                                    <i class="fa fa-check tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataStatistik.struktur }} <a style="font-size:14px">Data</a></span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-6">
                <div class="card mg-b-20  border-white">
                    <div class="card-body pd-y-0">
                        <div class="bg-white custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">RIGID PAVEMENT</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-primary">
                                    <i class="fa fa-check tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataStatistik.rigid }} <a style="font-size:14px">Data</a></span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-xl-6">
                <div class="card mg-b-20  border-white">
                    <div class="card-body pd-y-0">
                        <div class="bg-white custom-fieldset mb-4">
                            <div class="clearfix">
                                <label style="color:black" class="text-bold">SARKAPJA</label>
                            </div>
                            <div class="d-flex align-items-center text-dark">
                                <div class="wd-40 wd-md-50 ht-40 ht-md-50 mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded card-icon-primary">
                                    <i class="fa fa-check tx-dark tx-20"></i>
                                </div>
                                <div>
                                    <h2 class="tx-20 tx-sm-18 tx-md-24 mb-0 mt-2 mt-sm-0 tx-normal tx-rubik tx-dark"><span class="counter">{{ dataStatistik.sarkapja }} <a style="font-size:14px">Data</a></span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
    export default {
        metaInfo: {
            title: 'DTJMT',
            titleTemplate: '%s - Dashboard'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataStatistik: [],
                keywords: ''
            }
        },
        created() {
            this.getData();
        },
        components: {

        },
        methods: {
            getData() {
                this.$http.get(this.baseUrl + 'admin/input/statistik', {
                    params: {
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        this.dataStatistik = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
        },
    };
</script>