<template>
    <div>
        <div class="header-horizontal mb-4 border-bottom">
            <div class="d-flex align-items-center justify-content-between h-100">
                <div class="ml-4">
                    <div v-if="school_info.header_logo == ''" class="skeleton-bar">
                        <div class="sb-sm"></div>
                        <div class="sb-xs"></div>
                    </div>
                    <router-link v-if="school_info.header_logo" to="/"><img :src="school_info.header_logo" alt="Logo FTSL" :style="school_info.header_logo_style">
                    </router-link>
                </div>
                <div class="show-mobile">
                    <div class="d-flex align-items-center">
                    </div>
                </div>

                <div class="mr-4 hide-mobile">
                    <div class="hh-nav-list d-flex align-items-center text-right">
                        <div v-if="!userdata.logged_in">
                            <router-link to="/sign-in" class="btn btn-primary">
                                <div class="d-flex align-items-center">
                                    <div><span class="fe fe-users mr-2"></span></div>
                                    <div>Mahasiswa</div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="!userdata.logged_in" class="ml-2">
                            <router-link to="/sign-admin" class="btn btn-primary">
                                <div class="d-flex align-items-center">
                                    <div><span class="fe fe-user mr-2"></span></div>
                                    <div>Administrator</div>
                                </div>
                            </router-link>
                        </div>
                        <!-- Bagian ini muncul ketika sudah login -->
                        <div v-if="userdata.logged_in && userdata.role == 'siswa'" class="ml-2">
                            <router-link to="/progress/kemajuan" class="btn btn-primary">
                                <div class="d-flex align-items-center">
                                    <div>Progress</div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="userdata.logged_in && userdata.role == 'administrator'" class="ml-2">
                            <router-link to="/admin/dashboard" class="btn btn-primary">
                                <div class="d-flex align-items-center">
                                    <div>Beranda</div>
                                </div>
                            </router-link>
                        </div>
                        <div v-if="userdata.logged_in && userdata.role == 'siswa'" class="ml-2 dropdown">
                            <button class="btn btn-white dropdown-toggle btn-if-icon" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Akun Saya</button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
                                <div class="dropdown-item">
                                    <span class="fe fe-user mr-1"></span>{{userdata.name}}
                                    <div class="d-block text-sm">
                                        <span class="fe fe-clipboard mr-1"></span>{{userdata.username}}
                                    </div>
                                </div>
                                <hr class="dropdown-divider">
                                <router-link to="/mahasiswa/profil" class="dropdown-item"><span class="fe fe-settings mr-1"></span>Profil</router-link>
                                <hr class="dropdown-divider">
                                <router-link to="/signout" class="dropdown-item"><span class="fe fe-power mr-1"></span>Keluar</router-link>
                            </div>
                        </div>
                        <div v-if="userdata.logged_in && userdata.role == 'administrator'" class="ml-2 dropdown">
                            <button class="btn btn-white dropdown-toggle btn-if-icon" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Akun Saya</button>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
                                <div class="dropdown-item">
                                    <span class="fe fe-user mr-1"></span>{{userdata.name}}
                                    <div class="d-block text-sm">
                                        <span class="fe fe-clipboard mr-1"></span>{{userdata.username}}
                                    </div>
                                </div>
                                <hr class="dropdown-divider">
                                <router-link to="/signout" class="dropdown-item"><span class="fe fe-power mr-1"></span>Keluar</router-link>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <slot />
        <div class="d-block bg-dark text-white text-center text-sm">
            <div class="d-block p-3">
                Fakultas Teknik Sipil dan Lingkungan ITB
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex'
    export default {
        mounted() {
            this.$store.dispatch('loadUserData');
            this.$store.dispatch('loadSchoolInfo');
        },
        computed: mapState(['userdata', 'school_info']),
    }
</script>