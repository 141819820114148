<template>
    <div class="page-container">
        <div class="page-sidebar">
            <div class="logo">
                <a class="logo-img">		
                    <img class="desktop-logo" style="width:130px;height:130px" src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/8aad1fb83bbf248bd5e37c8d/pngegg.png" alt="">
                    <img class="small-logo" src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/8aad1fb83bbf248bd5e37c8d/pngegg.png" alt="">
                </a>			
                <i class="ion-ios-close-empty" id="sidebar-toggle-button-close"></i>
            </div>

            <div class="page-sidebar-inner">
                <div class="page-sidebar-menu">
                    <ul class="accordion-menu">
                        <li class="open active" v-for="(item,index) in dataMenu" :key="index">
                            <!-- If not parent menu -->
                            <a v-if="!item.parent">
                                <router-link :to="{ name: item.url }" >
                                    <h6 style="font-size:14px">
                                        <img style="width:25px" :src="'https://kdo.e-planningbmd.id/assets/images/'+item.icon" class="sidebar-img-icon">
                                        &nbsp;{{item.title}} 
                                    </h6>
                                </router-link>
                            </a>
                            <!-- If parent menu -->
                            <a v-if="item.parent">
                                <router-link :to="{ name: item.url }">
                                    <h6 style="font-size:14px">
                                        <img style="width:25px" :src="'https://kdo.e-planningbmd.id/assets/images/'+item.icon" class="sidebar-img-icon">
                                        &nbsp;{{item.title}} 
                                        <i class="accordion-icon fa fa-angle-left"></i>
                                    </h6>
                                </router-link>
                            </a>
                            <ul v-if="item.parent" class="sub-menu" style="display:block">
                                <li class="" v-for="(itemDetail,indexDetail) in item.sub_menu" :key="indexDetail">
                                    <router-link :to="{ name: itemDetail.url }" >
                                        <h6 style="font-size:14px">{{itemDetail.title}}</h6>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="page-content">
            <div class="page-header">
            <nav class="navbar navbar-expand-lg">
                <ul class="list-inline list-unstyled mg-r-20">
                    <!-- Mobile Toggle and Logo -->
                    <!-- <li class="list-inline-item align-text-top"><a class="hidden-md hidden-lg" href="" id="sidebar-toggle-button"><i class="ion-navicon tx-20"></i></a></li> -->
                    <!-- PC Toggle and Logo -->
                    <!-- <li class="list-inline-item align-text-top"><a class="text-dark hidden-xs hidden-sm" href="#" id="collapsed-sidebar-toggle-button"><i class="ion-navicon tx-20">b</i></a></li> -->
                </ul>

                <!--================================-->
                <!-- Mega Menu Start -->
                <!--================================-->
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav mr-auto">
                    </ul>
                </div>
                
                <div class="header-right pull-right">
                    <ul class="list-inline justify-content-end">
                        <li class="list-inline-item dropdown">
                        <a  href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="text-dark select-profile">Administrator</span>
                            <!-- <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/35aab6be5d7b1639fea026b0/Logo-Jasa-Marga-kecil.jpg" class="img-fluid wd-35 ht-35 rounded-circle" alt=""> -->
                        </a>
                        <div class="dropdown-menu dropdown-menu-right dropdown-profile shadow-2">
                            <div class="user-profile-area">
                                <div class="user-profile-heading">
                                    <div class="profile-thumbnail">
                                        <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/35aab6be5d7b1639fea026b0/Logo-Jasa-Marga-kecil.jpg" class="img-fluid wd-35 ht-35 rounded-circle" alt="">
                                    </div>
                                    <div class="profile-text">
                                    <h6>Administrator</h6>
                                    <span>Admin</span>
                                    </div>
                                </div>
                                <router-link to="/signout" class="dropdown-item"><i class="icon-power" aria-hidden="true"></i>Logout</router-link>
                            </div>
                        </div>
                        </li>
                        <li class="list-inline-item dropdown">
                        <a  href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/b89c37deb768b35123062875/Hamburger_icon.svg.png" class="img-fluid wd-25 ht-25 rounded-circle" alt="">
                        </a>
                        <div class="dropdown-menu dropdown-menu-right dropdown-profile shadow-2">
                            <div class="user-profile-area">
                                <div class="user-profile-heading">
                                    <div class="profile-thumbnail">
                                        <img src="https://scola-uploader.sgp1.digitaloceanspaces.com/upload/b89c37deb768b35123062875/Hamburger_icon.svg.png" class="img-fluid wd-25 ht-25 rounded-circle" alt="">
                                    </div>
                                    <div class="profile-text">
                                    <h6>MENU</h6>
                                    </div>
                                </div>
                                <router-link to="/admin/dashboard" class="dropdown-item"><i class="icon-home" aria-hidden="true"></i>Dashboard</router-link>
                                <router-link to="/admin/master/pengguna" class="dropdown-item"><i class="icon-book-open" aria-hidden="true"></i>Master</router-link>
                                <router-link to="/admin/master/pengguna" class="dropdown-item"><i class="ml-2 icon-options" aria-hidden="true"></i>Pengguna</router-link>
                                <router-link to="/admin/master/stasioning" class="dropdown-item"><i class="ml-2 icon-options" aria-hidden="true"></i>Stasioning</router-link>
                                <router-link to="/admin/master/klasifikasi" class="dropdown-item"><i class="ml-2 icon-options" aria-hidden="true"></i>Klasifikasi</router-link>
                                <router-link to="/admin/master/sub_klasifikasi" class="dropdown-item"><i class="ml-2 icon-options" aria-hidden="true"></i>Sub Klasifikasi</router-link>
                                <router-link to="/admin/master/item_klasifikasi" class="dropdown-item"><i class="ml-2 icon-options" aria-hidden="true"></i>Item Klasifikasi</router-link>
                                <router-link to="/admin/input" class="dropdown-item"><i class="icon-screen-desktop" aria-hidden="true"></i>Data Input</router-link>
                            </div>
                        </div>
                        </li>
                        <li class="list-inline-item align-text-top"><a class="hidden-md hidden-lg" href="" id="sidebar-toggle-button"></a></li>
                        <!-- Profile Dropdown End -->
                    </ul>
                </div>
                <!--/ Header Right End -->
            </nav>
            </div>
            
            <div class="page-inner">
            <div id="main-wrapper">

                <slot />

            </div>
            </div>
            <footer  class="page-footer">
                <div class="pd-t-4 pd-b-0 pd-x-20">
                    <div class="tx-10 tx-uppercase">
                        <p class="pd-y-10 mb-0">Copyright&copy; 2023</p>
                    </div>
                </div>
            </footer>	
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "SideBar",
        data() {
            return {
                dataMenu: []
            }
        },
        mounted() {
            this.$store.dispatch('loadSchoolInfo');
            this.getData();
        },
        computed: mapState(['school_info']),
        methods: {
            getData() {
                this.fetching = true;
                this.$http.get(this.$apiconfig + "admin/menu/list_menu")
                    .then((response) => {
                        this.dataMenu = response.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
        }
    }
</script>